import React from 'react'
import { graphql } from 'gatsby'
import LocatableDetail from "../components/locatable-detail"

export default ({ data }) => {
  const deals = data.allDatoCmsDeal.edges.flatMap(e => e.node).filter(d => d.activities.find(a => a.id === data.locatable.id))
  return (
    <LocatableDetail locatable={data.locatable} settings={data.datoCmsWebsiteLayout} deals={deals} />
  )
}

export const query = graphql`
  query ActivityQuery($id: String!) {
    datoCmsWebsiteLayout {
      sponsors {
        ...Sponsor
      }
    }
    allDatoCmsDeal {
      edges {
        node {
          ...DealFields
          activities {
            id
          }
        }
      }
    }
    locatable: datoCmsActivity(id: { eq: $id }) {
      id
      title
      subtitle
      summary
      description
      videoUrl
      requiresTicket
      presaleStart
      presaleEnd
      saleStart
      showareUrl
      saleEnd
      scheduleItems {
        title
        startDate
        endDate
        startTime
        endTime
      }
      pageHeader {
        ...HeroTitleBlock
      }
      sponsors {
        ...Sponsor
      }
      itemSponsors {
        ...Sponsor
      }
      discoverMore {
        ...DiscoverMore
      }
      heroImage {
        url
        fluid(maxWidth: 2800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      subcategories {
        title
        heroTitleBlock {
          ...HeroTitleBlock
        }
        sponsors {
          ...Sponsor
        }
        landingPageDiscoverMore {
          ...DiscoverMore
        }
      }
      universalTags {
        title
      }
      location {
        title
        building {
          id
          title
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      model {
        apiKey
      }
    }
  }
`
